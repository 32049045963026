<clr-modal [clrModalOpen]="isOpen" [clrModalClosable]="false">
    <h3 class="modal-title">{{title}}</h3>
    <div class="modal-body">
        <form clrForm clrLabelSize="4">
            <clr-input-container *ngFor="let item of dialogConfig">
                <label>{{item['label']}}</label>
                <input clrInput type="text" [(ngModel)]="data[item.key]" name="{{item.label}}" />
            </clr-input-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="save()">Ok</button>
    </div>
</clr-modal>