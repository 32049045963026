import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from 'src/app/core/services/report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  reportData; 

  constructor(private reportService: ReportService, private activeRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.activeRoute.params.subscribe(data => {
      
      if (data.report == "ticket") {
        this.reportData = this.reportService.ticketPrintOut(JSON.parse(data.data));
        // console.log(this.reportData);
      } else {
        this.reportData = this.reportService.buildReport("openTickets", JSON.parse(data.data));
      }


    });

  }

}
