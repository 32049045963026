import { Component, OnInit } from '@angular/core';
import { TicketService } from 'src/app/core/services/ticket.service';
import { DriverService } from 'src/app/core/services/driver.service';
import { TruckService } from 'src/app/core/services/truck.service';
import { CommodityService } from 'src/app/core/services/commodity.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { BillingEntityService } from 'src/app/core/services/billingEntity.service';

@Component({
  selector: 'app-newticket',
  templateUrl: './newticket.component.html',
  styleUrls: ['./newticket.component.scss']
})
export class NewticketComponent implements OnInit {

  public item: any = {
    "date": "",
    "deliveryDate": "",
    "billedEntity": "",
    "shippedFrom": "",
    "shippedTo": "",
    "commodity": "",
    "quantityDelivered": "",
    "assignedDriver": "",
    "truck": "",
    "trailer": "",
    "receivedBy": ""    
  };

  commodities$: Observable<[]>;
  trucks$: Observable<[]>;
  drivers$: Observable<[]>;
  billingEntities$: Observable<[]>;

  constructor(private billingEntities:BillingEntityService, private ticket: TicketService, private router: Router, private commodity: CommodityService, private driver: DriverService, private truck: TruckService) { }

  ngOnInit() {

    this.commodities$ = this.commodity.allCommodities();  
    this.drivers$ = this.driver.allDrivers();  
    this.trucks$ = this.truck.allTrucks();  
    this.billingEntities$ = this.billingEntities.allBillingEntity();  
  }

  submit() {

    this.ticket.createTicket(this.item).subscribe(
      response => {
        console.log(response);
        this.router.navigate(['/ticket', response['_id']]);
      },
      err => console.log(err)
    );
  }


}
