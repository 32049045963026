import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { BillingEntityService } from 'src/app/core/services/billingEntity.service';

@Component({
  selector: 'app-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss']
})
export class EntityComponent implements OnInit {

  items: any = [];
  entityId: any;

  constructor(private activeRoute: ActivatedRoute, private billingEntities: BillingEntityService) { }

  downloadFile(data: any) {

    var str = '';
    var header = "Load #,Delivery Date,Delivery From,Delivery To,Truck";

    //Add header to output str
    str += header;
    str += '\n';

    for (var i = 0; i < data.length; i++) {
      var line = '';
      if (line != '') line += ','

      if (data[i]['loadid']) {
        line += data[i]['loadid'].replace(/,/g, '');;
      } else {
        line += "";
      }
      
      line += ','
      if (data[i]['deliveryDate']) {
        line += data[i]['deliveryDate'].replace(/,/g, '');;
      } else {
        line += "";
      }

      line += ','
      if (data[i]['shippedFrom']) {
        line += data[i]['shippedFrom'].replace(/,/g, '');;
      } else {
        line += "";
      }

      line += ','
      if (data[i]['shippedTo']) {
        line += data[i]['shippedTo'].replace(/,/g, '');;
      } else {
        line += "";
      }

      line += ','
      if (data[i]['truck']['name']) {
        line += data[i]['truck']['name'].replace(/,/g, '');;
      } else {
        line += "";
      }


      str += line + '\n';
    }



    // const csvArray = header.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([str], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'entityReport.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  ngOnInit() {

    this.activeRoute.params.subscribe(routeParams => {

      if (routeParams.id) {
        this.entityId = routeParams.id;
        this.items = this.billingEntities.getTicketsByEntity(this.entityId).subscribe(result => {

          this.items = result;

        });

      }

    });

  }

}
