import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TruckService {

  constructor(private httpClient: HttpClient, private auth: AuthService) {}
 
  allTrucks(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/truck');
  }

  createTruck(data): Observable<[]> {
    return this.httpClient.post<[]>('https://dispatching.brewbrostrucking.com/api/truck', data);
  }

  editTruck(data): Observable<[]> {
    return this.httpClient.put<[]>('https://dispatching.brewbrostrucking.com/api/truck/' + data['_id'], data);
  }

  deleteTruck(id): Observable<any> {
    return this.httpClient.delete('https://dispatching.brewbrostrucking.com/api/truck/' + id)
  }

}
