import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TruckService } from 'src/app/core/services/truck.service';
import { CommodityService } from 'src/app/core/services/commodity.service';
import { TicketService } from 'src/app/core/services/ticket.service';
import { DriverService } from 'src/app/core/services/driver.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingEntityService } from 'src/app/core/services/billingEntity.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

  public deleteTicketModal = false;
  public item: any = {
    "billed": false,
    "date": "",
    "deliveryDate": "",
    "billedEntity": "",
    "shippedFrom": "",
    "shippedTo": "",
    "commodity": "",
    "quantityDelivered": "",
    "assignedDriver": "",
    "truck": "",
    "trailer": "",
    "receivedBy": ""
  };

  commodities$: Observable<[]>;
  trucks$: Observable<[]>;
  drivers$: Observable<[]>;
  billingEntities$: Observable<[]>;

  constructor(private billingEntities: BillingEntityService, private activeRoute: ActivatedRoute, private router: Router, private ticket: TicketService, private commodity: CommodityService, private driver: DriverService, private truck: TruckService) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(routeParams => {

      this.ticket.getOneTicket(routeParams.id).subscribe(
        response => {
          this.item = response;
          console.log(this.item);

          // var creationDate = new Date(this.item['date']);
          // var deliveryDate = new Date(this.item['deliveryDate']);

          this.item['date'] = moment.utc(this.item['date']).format('MM/DD/YYYY');
          this.item['deliveryDate'] =  moment.utc(this.item['deliveryDate']).format('MM/DD/YYYY');
          // console.log(moment.utc(this.item['date']).format('MM/DD/YYYY'));
        },
        err => console.log(err)
      );


    });


    this.commodities$ = this.commodity.allCommodities();
    this.drivers$ = this.driver.allDrivers();
    this.trucks$ = this.truck.allTrucks();
    this.billingEntities$ = this.billingEntities.allBillingEntity();

  }

  submit() {

    console.log(this.item);
    console.log(new Date(this.item['date']))
    console.log(new Date(this.item['deliveryDate']))

    this.ticket.editTicket(this.item).subscribe(
      response => {
        this.router.navigate(['/tickets']);
      },
      err => console.log(err)
    );
  }


  toggleDeleteTicketModal() {
    this.deleteTicketModal = !this.deleteTicketModal;
  }

  printTicket() {

    this.ticket.getTicket(this.item['_id']).subscribe(
      response => {
        console.log(response);
        this.router.navigate(['/report', "ticket", JSON.stringify(response)]);
      },
      err => console.log(err)
    );


  }


  deleteTicket() {
    this.ticket.deleteTicket(this.item['_id']).subscribe(
      response => {
        this.router.navigate(['/tickets']);
      },
      err => console.log(err)
    );
  }

}
