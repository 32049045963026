import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { TicketService } from 'src/app/core/services/ticket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  title: any = "Open";
  view = 0;
  unassignedTickets$: Observable<any[]>;
  archivedTickets$: Observable<any[]>;
  billedTickets$: Observable<any[]>;
  rows$: Observable<any[]>;

  constructor(private activeRoute: ActivatedRoute, private ticketService: TicketService, private router: Router) { }

  ngOnInit() {

    this.activeRoute.params.subscribe(routeParams => {

      if (routeParams.id) {
        this.view = routeParams.id;
      }

    });


    this.unassignedTickets$ = this.ticketService.unassignedTickets().pipe(
      map((tickets) => tickets.map(ticket => ({
        '_id': ticket['_id'],
        'dispatched': ticket['dispatched'],
        'date': ticket['date'],
        'deliveryDate': ticket['deliveryDate'],
        'billedEntity': ticket['billedEntity']['name'],
        'shipping': `${ticket['shippedFrom']}, ${ticket['shippedTo']}`,
        'assignedDriver': ticket['assignedDriver'] ? ticket['assignedDriver']['name'] : "",
        'truck': ticket['truck']['name'],
      })))
    );

    this.archivedTickets$ = this.ticketService.archivedTickets().pipe(
      map((tickets) => tickets.map(ticket => ({
        '_id': ticket['_id'],
        'dispatched': ticket['dispatched'],
        'date': ticket['date'],
        'deliveryDate': ticket['deliveryDate'],
        'billedEntity': ticket['billedEntity']['name'],
        'shipping': `${ticket['shippedFrom']}, ${ticket['shippedTo']}`,
        'assignedDriver': ticket['assignedDriver'] ? ticket['assignedDriver']['name'] : "",
        'truck': ticket['truck']['name'],
      })))
    );

    this.billedTickets$ = this.ticketService.billedTickets().pipe(
      map((tickets) => tickets.map(ticket => ({
        '_id': ticket['_id'],
        'dispatched': ticket['dispatched'],
        'date': ticket['date'],
        'deliveryDate': ticket['deliveryDate'],
        'billedEntity': ticket['billedEntity']['name'],
        'shipping': `${ticket['shippedFrom']}, ${ticket['shippedTo']}`,
        'assignedDriver': ticket['assignedDriver'] ? ticket['assignedDriver']['name'] : "",
        'truck': ticket['truck']['name'],
      })))
    );

    this.rows$ = this.unassignedTickets$;
  }

  public columnDefs: ColDef[] = [
    {
      field: 'date', headerName: 'Date', cellRenderer: (data) => {
        return moment(data.createdAt).format('MM/DD/YYYY')
      }
    },
    {
      field: 'deliveryDate', headerName: 'Delivery Date', cellRenderer: (data) => {
        return moment(data.createdAt).format('MM/DD/YYYY')
      }
    },
    { field: 'billedEntity', headerName: 'Billed Entity' },
    { field: 'shipping', headerName: 'Delivery From, To' },
    { field: 'assignedDriver', headerName: 'Driver' },
    { field: 'truck', headerName: 'Truck' }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };

  public gridOptions: GridOptions = {
    rowStyle: {}
  }


  // For accessing the Grid's API
  @ViewChild(AgGridAngular, { static: true }) agGrid!: AgGridAngular;

  // Example load data from sever
  onGridReady(params: GridReadyEvent) {
    // this.gridOptions.getRowStyle = function (params) {
    //   if (params.data['dispatched']) {
    //     return { background: '#BCE49A' }
    //   } else {
    //     return { background: '#FEDDD7' }
    //   }
    // }

    this.gridOptions.api.sizeColumnsToFit();
  }

  // Example of consuming Grid Event
  onCellClicked(e: CellClickedEvent): void {
    console.log('cellClicked', e);
    this.router.navigate(['ticket', e.data['_id']]);
  }

  // Example using Grid's API
  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }

  switchView(id) {
    this.view = id;
    if (id == 0) {
      this.title = "Open";
      this.rows$ = this.unassignedTickets$;
    } else if (id == 1) {
      this.title = "Archived";
      this.rows$ = this.archivedTickets$;
    } else if (id == 2) {
      this.title = "Billed";
      this.rows$ = this.billedTickets$;
    }

  }

  printOpenTickets() {
    this.unassignedTickets$.subscribe(data => {
      this.router.navigate(['/report', "Tickets", JSON.stringify(data)]);
    })
  }

}
