import { Component, OnInit } from '@angular/core';
import { BillingEntityService } from 'src/app/core/services/billingEntity.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-billing-entity',
  templateUrl: './new-billing-entity.component.html',
  styleUrls: ['./new-billing-entity.component.scss']
})
export class NewBillingEntityComponent implements OnInit {


  
  public item: any = {
    "name": ""
  };

  constructor(private billingEntity: BillingEntityService, private router: Router) { }

  ngOnInit() {
  }

  submit() {
    this.billingEntity.createBillingEntity(this.item).subscribe(
      response => {
        console.log(response);
        this.router.navigate(['/entities']);
      },
      err => console.log(err)
    );
  }

}
