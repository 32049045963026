import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class BillingEntityService {

  constructor(private httpClient: HttpClient, private auth: AuthService) {}
 
  allBillingEntity(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/billingEntity');
  }

  createBillingEntity(data): Observable<[]> {
    return this.httpClient.post<[]>('https://dispatching.brewbrostrucking.com/api/billingEntity', data);
  }

  getTicketsByEntity(id): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/billingEntity/tickets/' + id);
  }

  deleteBillingEntity(id): Observable<any> {
    return this.httpClient.delete('https://dispatching.brewbrostrucking.com/api/billingEntity/' + id)
  }

  editBillingEntity(data): Observable<[]> {
    return this.httpClient.put<[]>('https://dispatching.brewbrostrucking.com/api/billingEntity/' + data['_id'], data);
  }

  


  
}
