import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) { }

  
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        

        // add authorization header with jwt token if available
        let currentUser = this.auth.userValue;
        if (currentUser && currentUser.token) {
            console.log("Adding Token");
          request = request.clone({
            setHeaders: {
              'x-access-token': currentUser.token
            }
          });
        }

        return next.handle(request);
    }
}