import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CommodityService {

  constructor(private httpClient: HttpClient, private auth: AuthService) {}
 
  allCommodities(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/commodity');
  }

  createCommodity(data): Observable<[]> {
    return this.httpClient.post<[]>('https://dispatching.brewbrostrucking.com/api/commodity', data);
  }

  deleteCommodity(id): Observable<any> {
    return this.httpClient.delete('https://dispatching.brewbrostrucking.com/api/commodity/' + id)
  }

  editCommodity(data): Observable<[]> {
    return this.httpClient.put<[]>('https://dispatching.brewbrostrucking.com/api/commodity/' + data['_id'], data);
  }


}
