import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { TicketService } from 'src/app/core/services/ticket.service';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  unassignedTickets$: Observable<any[]>;

  constructor(private router: Router, private ticketService: TicketService) { }

  ngOnInit() {
    this.unassignedTickets$ = this.ticketService.unassignedTickets().pipe(
      map((tickets) => tickets.map(ticket => ({
        '_id': ticket['_id'],
        'dispatched': ticket['dispatched'],
        'loadid': ticket['loadid'],
        'deliveryDate': ticket['deliveryDate'],
        'billedEntity': ticket['billedEntity']['name'],
        'shipping': `${ticket['shippedFrom']}, ${ticket['shippedTo']}`,
        'assignedDriver': ticket['assignedDriver'] ? ticket['assignedDriver']['name'] : "",
        'truck': ticket['truck']['name'],
      })))
    );

  }

  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    { field: 'loadid', headerName: 'Load Id' },
    {
      field: 'deliveryDate', headerName: 'Delivery Date', cellRenderer: (data) => {
        return moment(data.createdAt).format('MM/DD/YYYY')
      }
    },
    { field: 'billedEntity', headerName: 'Billed Entity' },
    { field: 'shipping', headerName: 'Delivery From, To' },
    { field: 'assignedDriver', headerName: 'Driver' },
    { field: 'truck', headerName: 'Truck' }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };

  public gridOptions: GridOptions = {
    rowStyle: {}
  }

  // Data that gets displayed in the grid
  public rowData$!: Observable<any>;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular, { static: true }) agGrid!: AgGridAngular;

  // Example load data from sever
  onGridReady(params: GridReadyEvent) {
    this.gridOptions.getRowStyle = function (params) {
      if (params.data['dispatched']) {
        return { background: '#BCE49A' }
      } else {
        return { background: '#FEDDD7' }
      }
    }

    this.gridOptions.api.sizeColumnsToFit();
  }

  // Example of consuming Grid Event
  onCellClicked(e: CellClickedEvent): void {
    console.log('cellClicked', e);
    this.router.navigate(['ticket', e.data['_id']]);
  }

  // Example using Grid's API
  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }

}
