import { Component, OnInit } from '@angular/core';
import { DriverService } from 'src/app/core/services/driver.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newdriver',
  templateUrl: './newdriver.component.html',
  styleUrls: ['./newdriver.component.scss']
})
export class NewdriverComponent implements OnInit {

  public item: any = {
    "name": ""
  };

  constructor(private driver: DriverService, private router: Router) { }

  ngOnInit() {
  }

  submit() {
    this.driver.createDriver(this.item).subscribe(
      response => {
        console.log(response);
        this.router.navigate(['/entities']);
      },
      err => console.log(err)
    );
  }


}
