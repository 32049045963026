import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MaintenanceService } from 'src/app/core/services/maintenace.service';
import { TechniciansService } from 'src/app/core/services/technicians.service';
import { TruckService } from 'src/app/core/services/truck.service';

@Component({
  selector: 'app-neworder',
  templateUrl: './neworder.component.html',
  styleUrls: ['./neworder.component.scss']
})
export class NeworderComponent implements OnInit {

  public item: any = {
    "truck": "",
    "inprogress": false,
    "hours": [],
    "completed": false,
    "project": false,
    "name": "",
    "description": "",
    "notes": "",
    "date": "", 
    "completedDate": ""
  };

  trucks$: Observable<[]>;
  technicians$: Observable<[]>;

  constructor(private truck: TruckService, private technicianService: TechniciansService, private maintenance: MaintenanceService, private router: Router) { }

  ngOnInit() {
    this.trucks$ = this.truck.allTrucks();  
    this.technicians$ = this.technicianService.allTechnicians();  
  }

  submit() {
    this.maintenance.createOrder(this.item).subscribe(
      response => {
        console.log(response);
        this.router.navigate(['/maintenance']);
      },
      err => console.log(err)
    );
  }

}
