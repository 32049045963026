import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './core/guards/auth.guard';
import { TicketsComponent } from './components/tickets/tickets.component';
import { TicketComponent } from './components/ticket/ticket.component';
import { NewticketComponent } from './components/newticket/newticket.component';
import { EntitiesComponent } from './components/entities/entities.component';
import { NewdriverComponent } from './components/newdriver/newdriver.component';
import { NewtruckComponent } from './components/newtruck/newtruck.component';
import { NewcommodityComponent } from './components/newcommodity/newcommodity.component';
import { NewBillingEntityComponent } from './components/new-billing-entity/new-billing-entity.component';
import { ReportComponent } from './components/report/report.component';
import { EntityComponent } from './components/entity/entity.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { OrderComponent } from './components/order/order.component';
import { NeworderComponent } from './components/neworder/neworder.component';
import { NewTechnicianComponent } from './components/newTechnician/newTechnician.component';
import { TruckComponent } from './components/truck/truck.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tickets/:id',
    component: TicketsComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: 'tickets',
    component: TicketsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'new-ticket',
    component: NewticketComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ticket/:id',
    component: TicketComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: 'report/:report/:data',
    component: ReportComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: 'entities/:id',
    component: EntityComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: 'entities',
    component: EntitiesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order/:id',
    component: OrderComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: 'new-order',
    component: NeworderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'new-driver',
    component: NewdriverComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'new-technician',
    component: NewTechnicianComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'new-truck',
    component: NewtruckComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'new-commodity',
    component: NewcommodityComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'new-billingentity',
    component: NewBillingEntityComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'truck/:id',
    component: TruckComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: '',
    component: LoginComponent
  },
  { path: '',   redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
