import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  constructor(private httpClient: HttpClient, private auth: AuthService) {}
 
  allDrivers(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/driver');
  }

  createDriver(data): Observable<[]> {
    return this.httpClient.post<[]>('https://dispatching.brewbrostrucking.com/api/driver', data);
  }

  deleteDriver(id): Observable<[]> {
    console.log('Deleting Driver', id);
    return this.httpClient.delete<[]>('https://dispatching.brewbrostrucking.com/api/driver/' + id);
  }

  editDriver(data): Observable<[]> {
    return this.httpClient.put<[]>('https://dispatching.brewbrostrucking.com/api/driver/' + data['_id'], data);
  }

}
