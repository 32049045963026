<clr-main-container>
  <app-header title="Tickets"></app-header>
  <nav class="subnav">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': view == 0 }" (click)="switchView(0)">Open</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': view == 1 }" (click)="switchView(1)">Archive</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': view == 2 }" (click)="switchView(2)">Billed</a>
      </li>
    </ul>
  </nav>

  <div class="content-container">
    <div class="content-area">

      <h2>Tickets {{title}}</h2>

      <div *ngIf="view == 0">
        <button [routerLink]="['/new-ticket']" class="btn btn-outline">Add Ticket</button>
        <button (click)="printOpenTickets()" class="btn btn-outline">Print</button>
      </div>

      <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-alpine" [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef" [gridOptions]="gridOptions" [rowData]="rows$ | async"
        [rowSelection]="'multiple'" [animateRows]="true" (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClicked($event)">
      </ag-grid-angular>






    </div>
  </div>
</clr-main-container>