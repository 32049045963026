import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';

import { MaintenanceService } from 'src/app/core/services/maintenace.service';
import { TruckService } from 'src/app/core/services/truck.service';
import { TechniciansService } from 'src/app/core/services/technicians.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  public item: any = {
    "truck": "",
    "inprogress": false,
    "hours": [],
    "completed": false,
    "name": "",
    "description": "",
    "notes": "",
    "date": "",
    "completedDate": "",
    "mileage": 0
  };

  trucks$: Observable<[]>;
  technicians$: Observable<[]>;
  private orderId: any = "";
  constructor(private activeRoute: ActivatedRoute, private technicianService: TechniciansService, private truck: TruckService, private maintenance: MaintenanceService, private router: Router) { }

  ngOnInit() {
    this.trucks$ = this.truck.allTrucks();
    this.technicians$ = this.technicianService.allTechnicians();

    this.activeRoute.params.subscribe(routeParams => {
      this.orderId = routeParams.id;
      this.maintenance.getOrder(this.orderId).subscribe(
        response => {
          this.item = response;

          this.item['date'] = moment.utc(this.item['date']).format('MM/DD/YYYY');
          this.item['completedDate'] = moment.utc(this.item['completedDate']).format('MM/DD/YYYY');
          // console.log(moment.utc(this.item['date']).format('MM/DD/YYYY'));
        },
        err => console.log(err)
      );
    });

  }

  files: File[] = [];

  addHours() {
    this.item.hours.push({
      technician: '',
      duration: ''
    })
  }

  deleteHours(index: any) {
    this.item.hours.splice(index, 1);
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);

    var fd = new FormData(document.forms[0]);

    fd.append("file", this.files[0]);

    console.log(fd);

    this.maintenance.uploadFile(fd, this.orderId).subscribe((res: any) => {
      console.log('Upload File Response', res);
    });
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  openFile(id: any) {
    window.open('https://dispatching.brewbrostrucking.com/file/' + id, '_blank');
  }

  submit() {
    this.maintenance.editOrder(this.item).subscribe(
      response => {
        console.log(response);
        this.router.navigate(['/maintenance']);
      },
      err => console.log(err)
    );
  }

  deleteTicket(id: string) {
    this.maintenance.deleteOrder(this.item['_id']).subscribe(
      response => {
        console.log(response);
        this.router.navigate(['/maintenance']);
      },
      err => console.log(err)
    );
  }

  deleteFile(orderId: string, filename: any) {
    console.log('Deleting this file', filename);
    this.maintenance.deleteFile(orderId, filename).subscribe(
      response => {
        console.log(response);
        this.router.navigate(['/maintenance']);
      },
      err => console.log(err)
    );
  }

}
