import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TechniciansService {

  constructor(private httpClient: HttpClient, private auth: AuthService) {}
 
  allTechnicians(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/technician');
  }

  createTechnician(data): Observable<[]> {
    return this.httpClient.post<[]>('https://dispatching.brewbrostrucking.com/api/technician', data);
  }

  deleteTechnician(id): Observable<any> {
    return this.httpClient.delete('https://dispatching.brewbrostrucking.com/api/technician/' + id)
  }

  editTechnician(data): Observable<[]> {
    return this.httpClient.put<[]>('https://dispatching.brewbrostrucking.com/api/technician/' + data['_id'], data);
  }


}
