<clr-main-container>
  <app-header title="Maintenance"></app-header>
  <nav class="subnav">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': view == 0 }" (click)="switchView(0)">Dashboard</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': view == 1 }" (click)="switchView(1)">Pending</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': view == 2 }" (click)="switchView(2)">In Progress</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': view == 3 }" (click)="switchView(3)">Closed</a>
      </li>
    </ul>
  </nav>
  <div class="content-container">
    <div class="content-area">

      <div *ngIf="view == 0">
    

        <button [routerLink]="['/new-order']" class="btn btn-outline">Create Order</button>

        <div class="clr-row">
          <div class="clr-col-sm-12 clr-col-md-6">
            <div class="card">
              <div class="card-header">
                Projects
              </div>
              <div class="card-block">

                <div *ngFor="let item of (orders$ | async)" [routerLink]="['/order', item['_id']]" class="mItem">
                  <div class="mHeader">
                    <div class="mTitle">{{item.name}}</div>
                    <div class="mTruck">{{item.truck}}</div>
                  </div>
                  <div class="mDesc">
                    {{item.description}}
                  </div>
                  <div *ngIf="item.notes" class="ifNotes">
                    ...
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div class="clr-col-sm-12 clr-col-md-6">
            <div class="card">
              <div class="card-header">
                In Progress
              </div>
              <div class="card-block">

                <div *ngFor="let item of ordersInprogress$ | async " [routerLink]="['/order', item['_id']]" class="mItem">
                  <div class="mHeader">
                    <div class="mTitle">{{item.name}}</div>
                    <div class="mTruck">{{item.truck}}</div>
                  </div>
                  <div class="mDesc">
                    {{item.description}}
                  </div>
                  <div *ngIf="item.notes" class="ifNotes">
                    ...
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="view == 1">
        <ag-grid-angular style="width: 100%; height: 600px" class="ag-theme-alpine" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
        [rowData]="orders$ | async" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
      </ag-grid-angular>


      </div>

      <div *ngIf="view == 2">
        <ag-grid-angular style="width: 100%; height: 600px" class="ag-theme-alpine" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
        [rowData]="ordersInprogress$ | async" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
      </ag-grid-angular>



      </div>


      <div *ngIf="view == 3">
        <ag-grid-angular style="width: 100%; height: 600px" class="ag-theme-alpine" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
        [rowData]="closedOrders$ | async" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
      </ag-grid-angular>

      </div>

    </div>


  </div>





</clr-main-container>