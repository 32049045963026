<clr-main-container>
    <app-header title="Edit Ticket"></app-header>

    <div class="content-container">
        <div class="content-area">

            <h2>Edit Ticket</h2>

            <form clrForm>

                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox [(ngModel)]="item['dispatched']" name="dispatched" />
                    <label>Dispatched</label>
                  </clr-checkbox-wrapper>
                  
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox [(ngModel)]="item['billed']" name="billed" />
                    <label>Billed</label>
                  </clr-checkbox-wrapper>
                  
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox [(ngModel)]="item['archived']" name="archived" />
                    <label>Archived</label>
                  </clr-checkbox-wrapper>

                  <clr-input-container>
                    <label>Load Id</label>
                    <input clrInput type="text" [(ngModel)]="item['loadid']" name="loadid" />
                </clr-input-container>

                <clr-date-container>
                    <label>Date</label>
                    <input type="date" clrDate name="date" [(ngModel)]="item['date']">
                </clr-date-container>
                <clr-date-container>
                    <label>Delivery Date</label>
                    <input type="date" clrDate name="deliveryDate" [(ngModel)]="item['deliveryDate']">
                </clr-date-container>
                <clr-select-container>
                    <label>Billing Entity</label>
                    <select clrSelect name="billedEntity" [(ngModel)]="item['billedEntity']">
                        <option selected disabled hidden></option>
                        <option *ngFor="let entity of (billingEntities$ | async)" value="{{entity['_id']}}">
                            {{entity['name']}}</option>
                    </select>
                </clr-select-container>
                <clr-input-container>
                    <label>Shipping From</label>
                    <input clrInput type="text" [(ngModel)]="item['shippedFrom']" name="shippedFrom" />
                </clr-input-container>
                <clr-input-container>
                    <label>Delivered To</label>
                    <input clrInput type="text" [(ngModel)]="item['shippedTo']" name="shippedTo" />
                </clr-input-container>
                <clr-select-container>
                    <label>Commodity</label>
                    <select clrSelect name="commodities" [(ngModel)]="item['commodity']">
                        <option selected disabled hidden></option>
                        <option *ngFor="let commodity of (commodities$ | async)" value="{{commodity['_id']}}">
                            {{commodity['name']}}</option>
                    </select>
                </clr-select-container>
                <clr-input-container>
                    <label>Quantity Delivered</label>
                    <input clrInput type="text" [(ngModel)]="item['quantityDelivered']" name="quantityDelivered" />
                </clr-input-container>
                <clr-select-container>
                    <label>Assigned Driver</label>
                    <select clrSelect name="drivers" [(ngModel)]="item['assignedDriver']">
                        <option selected disabled hidden></option>
                        <option *ngFor="let driver of (drivers$ | async)" value="{{driver['_id']}}">{{driver['name']}}
                        </option>
                    </select>
                </clr-select-container>
                <clr-select-container>
                    <label>Truck</label>
                    <select clrSelect name="trucks" [(ngModel)]="item['truck']">
                        <option selected disabled hidden></option>
                        <option *ngFor="let truck of (trucks$ | async)" value="{{truck['_id']}}">{{truck['name']}}
                        </option>
                    </select>
                </clr-select-container>
                <clr-input-container>
                    <label>Trailer</label>
                    <input clrInput type="text" [(ngModel)]="item['trailer']" name="trailer" />
                </clr-input-container>
                <clr-input-container>
                    <label>Received By</label>
                    <input clrInput type="text" [(ngModel)]="item['receivedBy']" name="receivedBy" />
                </clr-input-container>
                <br>
                <br>
                <button class="btn btn-primary" type="submit" (click)="submit()">
                    Save
                </button>
                <button class="btn btn-danger" type="button" (click)="toggleDeleteTicketModal()">
                    Delete
                </button>
                <button class="btn btn-outline" (click)="printTicket()">Print</button>
            </form>


        </div>
    </div>


    <clr-modal [(clrModalOpen)]="deleteTicketModal">
        <h3 class="modal-title">Are you sure?</h3>
        <div class="modal-body">
            <p>That you would like to delete this ticket</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline" (click)="deleteTicketModal = false">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="deleteTicket()">Ok</button>
        </div>
    </clr-modal>

</clr-main-container>