import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private activeUser: BehaviorSubject<User>;
  public user$: Observable<User>;

  constructor(private http: HttpClient) {
    this.activeUser = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user$ = this.activeUser.asObservable();
  }


  public get userValue(): User {
    return this.activeUser.value;
  }


  login(email: string, password: string) {
    return this.http.post<any>('https://dispatching.brewbrostrucking.com/api/user/login', { email, password })
      .pipe(map(user => {
        // login success
        if (user && user.token) {
          // store user, token local to keep user logged in refreshes
          localStorage.setItem('user', JSON.stringify(user));
          this.activeUser.next(user);

        }
        return user;
        
      }));
  }

}
