<clr-main-container>
    <app-header title="Dashboard"></app-header>

    <div class="content-container">
        <div class="content-area">
            <h2>Open Tickets</h2>

            <ag-grid-angular style="width: 100%; height: 100%" class="ag-theme-alpine" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [gridOptions]="gridOptions" [rowData]="unassignedTickets$ | async" [rowSelection]="'multiple'"
                [animateRows]="true" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
            </ag-grid-angular>

        </div>
    </div>


</clr-main-container>