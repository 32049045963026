import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  constructor(private httpClient: HttpClient) { }
 
  unassignedTickets(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/ticket/open');
  }

  archivedTickets(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/ticket/archived');
  }

  billedTickets(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/ticket/billed');
  }

  getTicket(id): Observable<{}> {
    return this.httpClient.get<{}>('https://dispatching.brewbrostrucking.com/api/ticket/populate/' + id);
  }

  getOneTicket(id): Observable<{}> {
    return this.httpClient.get<{}>('https://dispatching.brewbrostrucking.com/api/ticket/edit/' + id);
  }

  allTickets(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/ticket');
  }

  createTicket(data): Observable<[]> {
    return this.httpClient.post<[]>('https://dispatching.brewbrostrucking.com/api/ticket', data);
  }

  editTicket(data): Observable<[]> {
    return this.httpClient.put<[]>('https://dispatching.brewbrostrucking.com/api/ticket/edit/' + data['_id'], data);
  }

  deleteTicket(id): Observable<[]> {
    return this.httpClient.delete<[]>('https://dispatching.brewbrostrucking.com/api/ticket/edit/' + id);
  }

}
