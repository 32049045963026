<clr-main-container>
    <app-header title="Entities"></app-header>
    <nav class="subnav">
        <ul class="nav">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': view == 0 }" (click)="switchView(0)">Drivers</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': view == 1 }" (click)="switchView(1)">Trucks</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': view == 2 }" (click)="switchView(2)">Commodities</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': view == 3 }" (click)="switchView(3)">Billing Entities</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': view == 4 }" (click)="switchView(4)">Technicians</a>
            </li>
        </ul>
    </nav>
    <div class="content-container">
        <div class="content-area">

            <h2>All {{title}}</h2>

            <div *ngIf="view == 0">
                <button  [routerLink]="['/new-driver']" class="btn btn-outline">Add Driver</button>
                
                <clr-datagrid>
                    <clr-dg-column>Name</clr-dg-column>
                    <clr-dg-column></clr-dg-column>
                
                    <clr-dg-row *ngFor="let driver of (drivers$ | async)">
                        <clr-dg-cell (click)="openEntity(driver)">{{driver.name}}</clr-dg-cell>
                        <clr-dg-cell (click)="deleteDriver(driver['_id'])">Delete</clr-dg-cell>
                    </clr-dg-row>
                
                    <clr-dg-footer>{{(drivers$| async)?.length}} Drivers</clr-dg-footer>
                </clr-datagrid>

         
            </div>
            
            <div *ngIf="view == 1">
                <button  [routerLink]="['/new-truck']" class="btn btn-outline">Add Truck</button>
                
                <clr-datagrid>
                    <clr-dg-column>Name</clr-dg-column>
                    <clr-dg-column>Make</clr-dg-column>
                    <clr-dg-column>Model</clr-dg-column>
                    <clr-dg-column>VIN</clr-dg-column>
                    <clr-dg-column>Mileage</clr-dg-column>
                    <clr-dg-column></clr-dg-column>
                
                    <clr-dg-row *ngFor="let truck of (trucks$ | async)">
                        <clr-dg-cell (click)="openEntity(truck)">{{truck.name}}</clr-dg-cell>
                        <clr-dg-cell>{{truck.make}}</clr-dg-cell>
                        <clr-dg-cell>{{truck.model}}</clr-dg-cell>
                        <clr-dg-cell>{{truck.vin}}</clr-dg-cell>
                        <clr-dg-cell>{{truck.mileage}}</clr-dg-cell>
                        <clr-dg-cell (click)="deleteTruck(truck['_id'])">Delete</clr-dg-cell>
                    </clr-dg-row>
                
                    <clr-dg-footer>{{(trucks$| async)?.length}} Trucks</clr-dg-footer>
                </clr-datagrid>
         
            </div>
            <div *ngIf="view == 2">
            
                <button  [routerLink]="['/new-commodity']" class="btn btn-outline">Add Commodity</button>

                <clr-datagrid>
                    <clr-dg-column>Name</clr-dg-column>
                    <clr-dg-column></clr-dg-column>
                
                    <clr-dg-row *ngFor="let commodity of (commodities$ | async)">
                        <clr-dg-cell (click)="openEntity(commodity)">{{commodity.name}}</clr-dg-cell>
                        <clr-dg-cell (click)="deleteCommodity(commodity['_id'])">Delete</clr-dg-cell>
                    </clr-dg-row>
                
                    <clr-dg-footer>{{(commodities$| async)?.length}} Commodities</clr-dg-footer>
                </clr-datagrid>
                

            </div>

            <div *ngIf="view == 3">
            
                <button  [routerLink]="['/new-billingentity']" class="btn btn-outline">Add Billing Entity</button>

                <clr-datagrid>
                    <clr-dg-column>Name</clr-dg-column>
                    <clr-dg-column></clr-dg-column>
                    <clr-dg-column></clr-dg-column>
                
                    <clr-dg-row *ngFor="let entity of (billingEntities$ | async)">
                        <clr-dg-cell (click)="openEntity(entity)">{{entity.name}}</clr-dg-cell>
                        <clr-dg-cell [routerLink]="['/entities', entity._id]">Open Report</clr-dg-cell>
                        <clr-dg-cell (click)="deleteEntity(entity['_id'])">Delete</clr-dg-cell>
                    </clr-dg-row>
                
                    <clr-dg-footer>{{(billingEntities$| async)?.length}} Billing Entities</clr-dg-footer>
                </clr-datagrid>

            </div>

            
            <div *ngIf="view == 4">
            
                <button  [routerLink]="['/new-technician']" class="btn btn-outline">Add Technician</button>

                <clr-datagrid>
                    <clr-dg-column>Name</clr-dg-column>
                    <clr-dg-column></clr-dg-column>
                
                    <clr-dg-row *ngFor="let technician of (technicians$ | async)">
                        <clr-dg-cell (click)="openEntity(technician)">{{technician.name}}</clr-dg-cell>
                        <clr-dg-cell (click)="deleteTechnician(technician['_id'])">Delete</clr-dg-cell>
                    </clr-dg-row>
                
                    <clr-dg-footer>{{(technicians$| async)?.length}} technicians</clr-dg-footer>
                </clr-datagrid>

            </div>

        </div>
    </div>
</clr-main-container>

<brew-bros-dialog (onClose)="dialogHasClosed()" (onSave)="dialogHasSaved($event)"  [isOpen]="dialogOpen" [title]="dialogTitle" [data]="dialogData" [dialogConfig]="dialogConfig"></brew-bros-dialog>