import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaintenanceService } from 'src/app/core/services/maintenace.service';
import { ClrDatagridSortOrder } from '@clr/angular';
import { Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  public descSort = ClrDatagridSortOrder.DESC;
  title: any = "Open Orders";
  view = 0;
  orders$: Observable<any[]>;
  ordersInprogress$: Observable<any[]>;
  closedOrders$: Observable<any[]>;

  constructor(private maintenance: MaintenanceService, private router: Router) { }

  ngOnInit() {

    this.orders$ = this.maintenance.openOrders().pipe(
      map((orders) => orders.map(order => ({
        '_id': order['_id'],
        'name': order['name'],
        'truck': order['truck']['name'],
        'description': order['description'],
        'notes': order['notes'],
        'completedDate': moment(order['completedDate']).format('MM/DD/YYYY')
      }))));

    this.ordersInprogress$ = this.maintenance.inprogressOrder().pipe(
      map((orders) => orders.map(order => ({
        '_id': order['_id'],
        'name': order['name'],
        'truck': order['truck']['name'],
        'description': order['description'],
        'notes': order['notes'],
        'completedDate': moment(order['completedDate']).format('MM/DD/YYYY')
      }))));

    this.closedOrders$ = this.maintenance.closedOrders().pipe(
      map((orders) => orders.map(order => ({
        '_id': order['_id'],
        'name': order['name'],
        'truck': order['truck']['name'],
        'description': order['description'],
        'notes': order['notes'],
        'completedDate': moment(order['completedDate']).format('MM/DD/YYYY')
      }))));

  }

  switchView(id) {
    this.view = id;
    if (id == 0) {
      this.title = "Open Orders";
    } else if (id == 1) {
      this.title = "Closed Orders";
    }
  }

  public gridOptions: GridOptions = {
    rowStyle: {}
  }

  // Data that gets displayed in the grid
  public rowData$!: Observable<any>;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular, { static: true }) agGrid!: AgGridAngular;

  // Example load data from sever
  onGridReady(params: GridReadyEvent) {
    this.gridOptions.api.sizeColumnsToFit();
  }


  public columnDefs: ColDef[] = [
    { field: 'name', headerName: 'Name' },
    { field: 'truck', headerName: 'Truck' },
    { field: 'description', headerName: 'Description' },
    { field: 'notes', headerName: 'Notes' },
    {
      field: 'completedDate', headerName: 'Completed Date', filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;

          if (dateAsString == null) {
            return 0;
          }

          // In the example application, dates are stored as mm/dd/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = dateAsString.split('/');
          const month = Number(dateParts[0]) - 1;
          const day = Number(dateParts[1]);
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);

          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        }
      }
    }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
  };

  // Example of consuming Grid Event
  onCellClicked(e: CellClickedEvent): void {
    console.log('cellClicked', e);
    this.router.navigate(['order', e.data['_id']]);
  }



}
