<div class="login-wrapper">
    <form class="login">
        <section class="title">
            <h3 class="welcome">Welcome to</h3>
            Brew Bros Dispatching
        </section>
        <div class="login-group">
            <clr-input-container>
                <label class="clr-sr-only">Username</label>
                <input type="text" name="username" clrInput placeholder="Username" [(ngModel)]="username"/>
            </clr-input-container>
            <clr-password-container>
                <label class="clr-sr-only">Password</label>
                <input type="password" name="password" clrPassword placeholder="Password" [(ngModel)]="password"/>
            </clr-password-container>
            <div *ngIf="incorrectLogin" class="error active">
                Incorrect Username/Password
            </div>
            <button (click)="login(username, password)" type="submit" class="btn btn-primary">NEXT</button>

        </div>
    </form>
</div>