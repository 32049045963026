
  <clr-header class="header-2">
    <div class="branding">
      <a class="nav-link">
        <span class="title">Brew Bros Dispatching</span>
      </a>
    </div>
    <div class="header-nav" [clr-nav-level]="1">
      <a routerLink="/dashboard" routerLinkActive="active" class="nav-link nav-text">Dashboard</a>
      <a routerLink="/tickets" routerLinkActive="active" class="nav-link nav-text">Tickets</a>
      <a routerLink="/entities" routerLinkActive="active" class="nav-link nav-text">Entities</a>
      <a routerLink="/maintenance" routerLinkActive="active" class="nav-link nav-text">Maintenance</a>
    </div>
    <div class="header-actions">
      <a (click)="logout()" class="nav-link nav-icon" aria-label="logout">
        <clr-icon shape="logout"></clr-icon>
      </a>
    </div>
  </clr-header>
 
