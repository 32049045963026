<clr-main-container>
  <app-header title="Entity"></app-header>
  <div class="content-container">
    <div class="content-area">
      <button (click)="downloadFile(items)" class="btn btn-outline">Export To CSV</button>
      <button class="btn btn-outline" printTitle="Entity Report" printSectionId="print-section"
        ngxPrint>Print</button>
        

        <div  id="print-section">
            <table style='width:100%; border-collapse: collapse; border: none;'>
                <tr>
                  <th style='border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Load #</th>
                  <th style='border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Delivery Date</th>
                  <th style='border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Delivery From</th>
                  <th style='border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Delivery To</th>
                  <!-- <th style='border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Driver</th> -->
                  <th style='border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Truck</th>
                </tr>
        
        
                <tr *ngFor="let item of items">
                  <td style='border: none; padding: 1px; font-size: 12px; text-align: center;'>{{item.loadid}}</td>
                  <td style='border: none; padding: 1px; font-size: 12px; text-align: center;'>{{item.deliveryDate | date:'d MMMM y':'UTC'}}</td>
                  <!-- <td style='border: none; padding: 1px; font-size: 12px; text-align: center;'>{{item.billedEntity?.name}}</td> -->
                  <td style='border: none; padding: 1px; font-size: 12px; text-align: center;'>{{item.shippedFrom}}</td>
                  <td style='border: none; padding: 1px; font-size: 12px; text-align: center;'>{{item.shippedTo}}</td>
                  <!-- <td style='border: none; padding: 1px; font-size: 12px; text-align: center;'>{{item.assignedDriver?.name}}</td> -->
                  <td style='border: none; padding: 1px; font-size: 12px; text-align: center;'>{{item.truck?.name}}</td>
                </tr>
        
                <!-- <clr-dg-footer>{{(billingEntities$| async)?.length}} Billing Entities</clr-dg-footer> -->
              </table>
        </div>
     
    </div>
  </div>
</clr-main-container>
