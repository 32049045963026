<clr-main-container>
    <app-header title="New Commodity"></app-header>

    <div class="content-container">
        <div class="content-area">

            <h2>Create Commodity</h2>

            <form clrForm>
                <clr-input-container>
                    <label>Name</label>
                    <input clrInput type="text" [(ngModel)]="item['name']" name="Example Commodity" />
                </clr-input-container>
                <button class="btn btn-primary" type="submit" [disabled]="!item['name']" (click)="submit()">
                    Submit
                </button>
            </form>


        </div>
    </div>
</clr-main-container>