import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { HeaderComponent } from './common/components/header/header.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TicketsComponent } from './components/tickets/tickets.component';
import { TicketComponent } from './components/ticket/ticket.component';
import { NewticketComponent } from './components/newticket/newticket.component';
import { EntitiesComponent } from './components/entities/entities.component';
import { NewcommodityComponent } from './components/newcommodity/newcommodity.component';
import { NewtruckComponent } from './components/newtruck/newtruck.component';
import { NewdriverComponent } from './components/newdriver/newdriver.component';
import { NewBillingEntityComponent } from './components/new-billing-entity/new-billing-entity.component';
import { ReportComponent } from './components/report/report.component';
// import {NgxPrintModule} from 'ngx-print';
import { EntityComponent } from './components/entity/entity.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { OrderComponent } from './components/order/order.component';
import { NeworderComponent } from './components/neworder/neworder.component';
import { AgGridModule } from 'ag-grid-angular';
import { NewTechnicianComponent } from './components/newTechnician/newTechnician.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './components/shared/dialog/dialog.component';
import { TruckComponent } from './components/truck/truck.component';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    HeaderComponent,
    TicketsComponent,
    TicketComponent,
    NewticketComponent,
    EntitiesComponent,
    NewcommodityComponent,
    NewtruckComponent,
    NewdriverComponent,
    NewBillingEntityComponent,
    ReportComponent,
    EntityComponent,
    MaintenanceComponent,
    OrderComponent,
    NeworderComponent,
    NewTechnicianComponent,
    DialogComponent,
    TruckComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    // Ng2SearchPipeModule,
    ClarityModule,
    AgGridModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // NgxPrintModule,
    NgChartsModule,
    NgxDropzoneModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
