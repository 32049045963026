import { Component, OnInit } from '@angular/core';
import { TruckService } from 'src/app/core/services/truck.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newtruck',
  templateUrl: './newtruck.component.html',
  styleUrls: ['./newtruck.component.scss']
})
export class NewtruckComponent implements OnInit {

  public item: any = {
    "name": "",
    "vin": ""
  };

  constructor(private truck: TruckService, private router: Router) { }

  ngOnInit() {
  }

  submit() {
    this.truck.createTruck(this.item).subscribe(
      response => {
        console.log(response);
        this.router.navigate(['/entities']);
      },
      err => console.log(err)
    );
  }

}
