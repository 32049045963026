import { Component, OnInit } from '@angular/core';
import { CommodityService } from 'src/app/core/services/commodity.service';
import { Observable } from 'rxjs';
import { DriverService } from 'src/app/core/services/driver.service';
import { TruckService } from 'src/app/core/services/truck.service';
import { BillingEntityService } from 'src/app/core/services/billingEntity.service';
import { ActivatedRoute } from '@angular/router';
import { TechniciansService } from 'src/app/core/services/technicians.service';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss']
})
export class EntitiesComponent implements OnInit {

  title: any = "Drivers";
  view = 0;
  commodities$: Observable<[]>;
  trucks$: Observable<[]>;
  drivers$: Observable<[]>;
  billingEntities$: Observable<[]>;
  technicians$: Observable<[]>;

  dialogOpen: boolean = false;
  dialogTitle: string = "Edit Truck";
  dialogData = {};
  dialogConfig = [];

  constructor(private activeRoute: ActivatedRoute, private technicians: TechniciansService, private commodity: CommodityService, private billingEntities: BillingEntityService, private driver: DriverService, private truck: TruckService) { }

  ngOnInit() {

    this.activeRoute.params.subscribe(routeParams => {

      if (routeParams.id) {
        this.view = routeParams.id;
      }

    });

    this.commodities$ = this.commodity.allCommodities();
    this.drivers$ = this.driver.allDrivers();
    this.trucks$ = this.truck.allTrucks();
    this.technicians$ = this.technicians.allTechnicians();
    this.billingEntities$ = this.billingEntities.allBillingEntity();

  }

  switchView(id) {
    this.view = id;
    if (id == 0) {
      this.title = "Drivers";
    } else if (id == 1) {
      this.title = "Trucks";
    } else if (id == 2) {
      this.title = "Commodities";
    } else if (id == 3) {
      this.title = "Billing Entities";
    } else if (id == 4) {
      this.title = "Technicians";
    }
  }

  openEntity(data) {
    console.log('Trying To Open');
    this.dialogOpen = true;
    this.dialogTitle = 'Edit ';
    if (this.view == 0) {
      this.dialogTitle += 'Driver';
      this.dialogConfig = [{
        label: 'Name',
        key: 'name'
      }];
    } else if (this.view == 1) {
      this.dialogTitle += 'Truck';
      this.dialogConfig = [
        {
          label: 'Name',
          key: 'name'
        },
        {
          label: 'Make',
          key: 'make'
        }, {
          label: 'Model',
          key: 'model'
        }, {
          label: 'Vin',
          key: 'vin'
        }, {
          label: 'Mileage',
          key: 'mileage'
        }
      ];
    } else if (this.view == 2) {
      this.dialogTitle += 'Commodity';
      this.dialogConfig = [{
        label: 'Name',
        key: 'name'
      }];
    } else if (this.view == 3) {
      this.dialogTitle += 'Billing Entity';
      this.dialogConfig = [{
        label: 'Name',
        key: 'name'
      }];
    } else if (this.view == 4) {
      this.dialogTitle += 'Technician';
      this.dialogConfig = [{
        label: 'Name',
        key: 'name'
      }];
    }


    this.dialogData = data;
  }

  dialogHasClosed() {
    this.dialogOpen = false;
    this.dialogTitle = "";
    this.dialogData = {};
    this.dialogConfig = [];
  }

  dialogHasSaved(data: any) {

    if (this.view == 0) {
      // Driver
      this.driver.editDriver(data).subscribe(result => {
        console.log(result)
      });
    
    } else if (this.view == 1) {

      // Truck
      this.truck.editTruck(data).subscribe(result => {
        console.log(result)
      });
     
    } else if (this.view == 2) {
      // Commodity
      this.commodity.editCommodity(data).subscribe(result => {
        console.log(result)
      });
    
    } else if (this.view == 3) {
      // Billing Entity
      this.billingEntities.editBillingEntity(data).subscribe(result => {
        console.log(result)
      });
     
    } else if (this.view == 4) {
      // Technician
      this.technicians.editTechnician(data).subscribe(result => {
        console.log(result)
      });
      
    }

    this.dialogHasClosed();
    
  
  }

  deleteDriver(id: string) {
    this.driver.deleteDriver(id).subscribe(_ => {
      this.drivers$ = this.driver.allDrivers();
    });
  }

  deleteTruck(id: string) {
    this.truck.deleteTruck(id).subscribe(_ => {
      this.trucks$ = this.truck.allTrucks();
    });
  }

  deleteCommodity(id: string) {
    this.commodity.deleteCommodity(id).subscribe(_ => {
      this.commodities$ = this.commodity.allCommodities();
    });
  }

  deleteEntity(id: string) {
    this.billingEntities.deleteBillingEntity(id).subscribe(_ => {
      this.billingEntities$ = this.billingEntities.allBillingEntity();
    });
  }

  deleteTechnician(id: string) {
    this.technicians.deleteTechnician(id).subscribe(_ => {
      this.technicians$ = this.technicians.allTechnicians();
    });
  }


}
