import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  incorrectLogin: boolean = false;
  username: any;
  password: any;

  constructor(private auth: AuthService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
  }

  login(username, password) {
    this.incorrectLogin = false;
    this.auth.login(username, password)
        .pipe(first())
        .subscribe(
            data => {
                // if (data['dispatching']) {
                  this.router.navigate(['/dashboard']);
                // } else {
                //   this.incorrectLogin = true;
                // }
            },
            error => {
                console.log(JSON.stringify(error))
            });
}


}
