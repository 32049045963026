<clr-main-container>
  <app-header title="Edit Order"></app-header>

  <div class="content-container">
    <div class="content-area">

      <h2>Edit Order</h2>

      <form clrForm>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="item['completed']" name="completed" />
          <label>Completed</label>
        </clr-checkbox-wrapper>

        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="item['inprogress']" name="inprogress" />
          <label>In Progress</label>
        </clr-checkbox-wrapper>

        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="item['project']" name="project" />
          <label>Project</label>
        </clr-checkbox-wrapper>

        <clr-date-container>
          <label>Date</label>
          <input type="date" clrDate name="date" [(ngModel)]="item['date']">
        </clr-date-container>

        <clr-date-container>
          <label>Completed Date</label>
          <input type="date" clrDate name="completedDate" [(ngModel)]="item['completedDate']">
        </clr-date-container>

        <clr-select-container>
          <label>Truck/Trailer</label>
          <select clrSelect name="trucks" [(ngModel)]="item['truck']">
            <option selected disabled hidden></option>
            <option *ngFor="let truck of (trucks$ | async)" value="{{truck['_id']}}">{{truck['name']}}</option>
          </select>
        </clr-select-container>

        <clr-input-container>
          <label>Mileage</label>
          <input clrInput type="number" [(ngModel)]="item['mileage']" name="Mileage" />
        </clr-input-container>

        <clr-input-container>
          <label>Title</label>
          <input clrInput type="text" [(ngModel)]="item['name']" name="Order Title" />
        </clr-input-container>

        <clr-input-container>
          <label>Description</label>
          <input clrInput type="text" [(ngModel)]="item['description']" name="Description" />
        </clr-input-container>

        <clr-input-container>
          <label>Notes</label>
          <input clrInput type="text" [(ngModel)]="item['notes']" name="Notes" />
        </clr-input-container>

        <!-- <clr-select-container>
          <label>Technicians</label>
          <select clrSelect name="technicians" [(ngModel)]="item['technician']">
            <option selected disabled hidden></option>
            <option *ngFor="let technician of (technicians$ | async)" value="{{technician['_id']}}">
              {{technician['name']}}</option>
          </select>
        </clr-select-container> -->

        <clr-datagrid class="mt-6">
          <clr-dg-column>Technician</clr-dg-column>
          <clr-dg-column>Duration</clr-dg-column>
          <clr-dg-column></clr-dg-column>

          <clr-dg-row *ngFor="let log of item['hours']; let i = index">
            <clr-dg-cell>
              <clr-select-container>
              <select clrSelect name="{{log['_id']}}-technician" [(ngModel)]="log['technician']">
                <option selected disabled hidden></option>
                <option *ngFor="let technician of (technicians$ | async)" value="{{technician['_id']}}">
                  {{technician['name']}}</option>
              </select>
              </clr-select-container>
            </clr-dg-cell>
            <clr-dg-cell> 
              <clr-input-container>
                <input clrInput type="text" [(ngModel)]="log['duration']" name="{{log['_id']}}-duration" />
              </clr-input-container>
            </clr-dg-cell>
               <clr-dg-cell (click)="deleteHours(i)">Delete</clr-dg-cell>
          </clr-dg-row>

        </clr-datagrid>

        <button class="mt-6 btn btn-primary" type="submit" (click)="addHours()">
          Add Technician
        </button>


        <clr-datagrid class="mt-6">
          <clr-dg-column>File</clr-dg-column>
          <clr-dg-column></clr-dg-column>

          <clr-dg-row *ngFor="let file of item['files']">
            <clr-dg-cell (click)="openFile(file)">{{file}}</clr-dg-cell>
            <clr-dg-cell (click)="deleteFile(item['_id'], file)">Delete</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>{{item['files'].length}} Files</clr-dg-footer>
        </clr-datagrid>


        <ngx-dropzone class="mt-12" (change)="onSelect($event)">
          <ngx-dropzone-label>Drag and drop files here.</ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>


        <button class="mt-6 btn btn-primary" type="submit" [disabled]="!item['name']" (click)="submit()">
          Submit
        </button>

        <button (click)="deleteTicket(item['_id'])" class="btn btn-outline">Delete</button>
      </form>


    </div>
  </div>
</clr-main-container>