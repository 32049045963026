<clr-main-container>
  <app-header title="Report"></app-header>

  <div class="content-container">
    <div class="content-area">
        <button class="btn btn-outline" printTitle="Dispatching Report" printSectionId="print-section" ngxPrint>Print</button> 

        <div id="print-section"> 
        <div [innerHTML]="reportData"></div>
        </div>
    </div>
  </div>
</clr-main-container>
