import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TechniciansService } from 'src/app/core/services/technicians.service';

@Component({
  selector: 'app-newTechnician',
  templateUrl: './newTechnician.component.html',
  styleUrls: ['./newTechnician.component.scss']
})
export class NewTechnicianComponent implements OnInit {

  public item: any = {
    "name": ""
  };

  constructor(private technician: TechniciansService, private router: Router) { }

  ngOnInit() {
  }

  submit() {
    this.technician.createTechnician(this.item).subscribe(
      response => {
        console.log(response);
        this.router.navigate(['/entities', 0]);
      },
      err => console.log(err)
    );
  }


}
