import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'brew-bros-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  @Input() isOpen?: boolean;
  @Input() title?: string;
  @Input() data?: any;
  @Input() dialogConfig?: any;

  @Output() onSave = new EventEmitter<string>();
  @Output() onClose = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.onClose.emit();
  }

  save() {
    this.onSave.emit(this.data);
  }

}
