<clr-main-container>
    <app-header title="Edit Order"></app-header>
  
    <div class="content-container">
      <div class="content-area">
  
        <h2>{{truck.name}}</h2>

        <canvas baseChart class="chart"
        [data]="lineChartData"
        [options]="lineChartOptions"
        [type]="lineChartType"
        (chartHover)="chartHovered($event)"
        (chartClick)="chartClicked($event)"></canvas>
<table>
  <tr>
    <th *ngFor="let label of lineChartData.labels">{{label}}</th>
  </tr>
  <tr *ngFor="let d of lineChartData.datasets; let i=index" [class]="'line-'+i">
    <td *ngFor="let label of lineChartData.labels; let j=index">{{d && d.data[j]}}</td>
  </tr>
</table>
  
      </div>
    </div>
  </clr-main-container>