import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor() { }

  public config = {
    openTickets: {
      title: "Open Tickets",
      showTitle: true,
      // grouped: [{key:"date", type: "date"}],
      grouped: [],
      values: [
        { alias: "Load #", key: "loadid", type: "string" },
        { alias: "Delivery Date", key: "deliveryDate", type: "date" },
        { alias: "Billed Entity", key: "billedEntity", type: "string" },
        { alias: "Delivery From", key: "shipping", type: "string" },
        { alias: "Driver", key: "assignedDriver", type: "string" },
        { alias: "Truck", key: "truck", type: "string" }
      ]
    }
  }


  totalBar(title, arr) {
    console.log(arr);
    var data = "";
    //Totals and averages
    var bft = 0;
    var amount = 0;
    var count = arr.length;

    for (var x = 0; x < arr.length; x++) {

      bft = bft + parseInt(arr[x].bft);
      amount = amount + parseFloat(arr[x].amount);

    }

    data += "<table class='table' style='width:100%; border-collapse: collapse; border: none;'>";

    //Header
    data += "<tr>";

    data += "<th  class='tableh' style='border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Count</th>";
    data += "<th  class='tableh' style='border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Bft</th>";
    data += "<th  class='tableh' style='border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Amount</th>";

    data += "</tr>";

    //Rows
    data += "<tr>";

    // if (name && value) {
    //   data += "<td class='tabled' style='border: none; padding: 1px; font-size: 12px; text-align: center;'>" + value + "</td>";
    // }

    data += "<td class='tabled' style='border: none; padding: 1px; font-size: 12px; text-align: center;'>" + count + "</td>";
    data += "<td  class='tabled' style='border: none; padding: 1px; font-size: 12px; text-align: center;'>" + bft + "</td>";
    data += "<td  class='tabled' style='border: none; padding: 1px; font-size: 12px; text-align: center;'>" + amount + "</td>";

    data += "<tr>";

    data += "</table>";


    return data;

  }

  ticketPrintOut(data) {

    let result = "<html>";
    
    result += "<center><h4>" + data.loadid + "</h4></center>";
    
    result += "<table class='tableFull' style='width:100%; border-collapse: collapse; border: none;'>";

    result += "<tr>";
    result += "<th class='tableh half' style=' width: 25%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Shipping From</th>";
    result += "<th class='tableh half' style=' width: 25%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Delivered To</th>";
    result += "</tr>";

    result += "<tr>";

    result += "<td class='tabled half' style=' width: 25%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>" + data.shippedFrom + "</td>";
    result += "<td class='tabled half' style=' width: 25%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>" + data.shippedTo + "</td>";
    result += "</tr>";

    result += "</table><br>";

    result += "<table class='tableFull' style='width:100%; border-collapse: collapse; border: none;'>";

    result += "<tr>";
    result += "<th class='tableh third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Date</th>";
    result += "<th class='tableh third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Delivery Date</th>";
    result += "<th class='tableh third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Billing Entity</th>";
    result += "</tr>";

    result += "<tr>";
    result += "<td class='tabled third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>" + (data.date).split("T")[0] + "</td>";
    result += "<td class='tabled third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>" + (data.deliveryDate).split("T")[0] + "</td>";
    result += "<td class='tabled third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>" + data.billedEntity['name'] + "</td>";
    result += "</tr>";

    result += "</table><br><br>";

    result += "<table class='tableFullBorder' style='width:100%;'>";

    result += "<tr>";
    result += "<th class='tableh third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Commodity</th>";
    result += "<th class='tableh third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Quantity</th>";
    result += "</tr>";

    result += "<tr>";
    result += "<td class='tabled third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>" + data.commodity['name'] + "</td>";
    
    if (data.quantityDelivered) {
      result += "<td class='tabled third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>" + data.quantityDelivered + "</td>";
    } else {
      result += "<td class='tabled third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>0</td>";
    }
   
    result += "</tr>";

    result += "</table><br>";

    result += "<table class='tableFull' style='width:100%; border-collapse: collapse; border: none;'>";

    result += "<tr>";
    result += "<th class='tableh third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Driver</th>";
    result += "<th class='tableh third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Truck</th>";
    result += "<th class='tableh third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>Trailer</th>";
    result += "</tr>";

    result += "<tr>";
    result += "<td class='tabled third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>" + data.assignedDriver['name'] + "</td>";
    result += "<td class='tabled third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>" + data.truck['name'] + "</td>";
    result += "<td class='tabled third' style=' width: 33%; border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>" + data.trailer + "</td>";
    result += "</tr>";

    result += "</table><br><br>";

    
    result += "</html>";

    return result;
    
  }

  buildReport(configName, data) {

    let config = this.config[configName];
    let result = "<html>";

    if (config.showTitle) {
      result += "<center><h4>" + config.title + "</h4></center>";
    }

    if (config.grouped.length) {

      var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };


      var seperated = groupBy(data, config.grouped[0].key);

      if (config.grouped.length > 1) {
        var i = 1;
      } else {
        var i = 0;
      }

      for (var i = i; i < config.grouped.length; i++) {

        for (var x = 0; x < Object.keys(seperated).length; x++) {

          seperated[Object.keys(seperated)[x]] = groupBy(seperated[Object.keys(seperated)[x]], config.grouped[i].key);

        }

        for (let item in seperated) {
          // console.log(seperated[item]);


          result += "<table class='table' style='width:100%; border-collapse: collapse; border: none;'>";

          result += "<tr>";

          result += "<td class='tabledlw' style='border: none; width: 15%; padding: 1px; font-size: 16px; text-align: right;'>";

          if (config.grouped[i].type == "date") {
            var date = new Date(item);
            result += "<h6 style='font-weight:bold; font-size: 16px;' class='title'>" + date.toLocaleDateString("en-US") + "</h6>";
          } else {
            result += "<h6 style='font-weight:bold; font-size: 16px;' class='title'>" + item + "</h6>";
          }

          result += "</td>";





          result += "<td class='tabledl' style='border: none; padding: 1px; font-size: 12px; text-align: left;'>";



          for (let item2 in seperated[item]) {

            if (config.grouped.length > 1) {
              result += this.totalBar(item2, seperated[item][item2]);
            } else {
              result += this.totalBar(item2, seperated[item][item2]);
            }

          }



          result += "</td>";



          result += "</tr>";


          result += "</table>";


          result += "<hr>";
        }

      }

    } else {


      //Table Headers
      result += "<table class='table' style='width:100%; border-collapse: collapse; border: none;'>";
      result += "<tr>";

      for (var i = 0; i < config.values.length; i++) {
        result += "<th class='tableh' style='border: none; padding: 1px;  word-break:break-all; text-decoration: underline; font-size: 12px; text-align: center;'>" + config.values[i].alias + "</th>";
      }

      result += "</tr>";
      //End Table Headers


      //Table Data
      for (var i = 0; i < data.length; i++) {
        result += "<tr>";

        for (var x = 0; x < config.values.length; x++) {

          if (data[i][config.values[x].key] || data[i][config.values[x].parentKey]) {

            if (config.values[x].type == "date") {
              var date = new Date(data[i][config.values[x].key]);
              result += "<td class='tabled' style='border: none; padding: 1px; font-size: 12px; text-align: center;'>" + date.toLocaleDateString("en-US") + "</td>";
            } else {

              if (config.values[x].parentKey) {
                result += "<td class='tabled' style='border: none; padding: 1px; font-size: 12px; text-align: center;'>" + data[i][config.values[x].parentKey][config.values[x].key] + "</td>";
              } else {
                result += "<td class='tabled' style='border: none; padding: 1px; font-size: 12px; text-align: center;'>" + data[i][config.values[x].key] + "</td>";
              }

            }


          } else {
            result += "<td class='tabled' style='border: none; padding: 1px; font-size: 12px; text-align: center;'>0</td>";
          }

        }

        result += "</tr>";

      }

      result += "</table>";
      result += "<hr>";
      //End Table data
    }
    return result;
  }

}
