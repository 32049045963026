import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  constructor(private httpClient: HttpClient, private auth: AuthService) {}
 
  allOrders(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/order/');
  }

  createOrder(data): Observable<[]> {
    return this.httpClient.post<[]>('https://dispatching.brewbrostrucking.com/api/order/', data);
  }

  pendingOrder(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/order/pending/');
  }

  inprogressOrder(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/order/inprogress/');
  }

  openOrders(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/order/open/');
  }

  
  closedOrders(): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/order/closed/');
  }


  getOrder(_id: any): Observable<[]> {
    return this.httpClient.get<[]>('https://dispatching.brewbrostrucking.com/api/order/' + _id);
  }

  editOrder(data): Observable<[]> {
    return this.httpClient.put<[]>('https://dispatching.brewbrostrucking.com/api/order/' + data['_id'], data);
  }

  deleteOrder(_id: any): Observable<any> {
    return this.httpClient.delete<any>('https://dispatching.brewbrostrucking.com/api/order/' + _id);
  }

  uploadFile(fileData, orderId) {
    return this.httpClient.post('https://dispatching.brewbrostrucking.com/file/upload/' + orderId, fileData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event: any) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    ); 
  }
  

  deleteFile(orderId: string, filename: any): Observable<any> {
    return this.httpClient.post<any>('https://dispatching.brewbrostrucking.com/delete/file/' + filename, {
      orderId: orderId
    });
  }


}
