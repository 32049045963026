<clr-main-container>
  <app-header title="New Order"></app-header>

  <div class="content-container">
    <div class="content-area">

      <h2>Create Order</h2>

      <form clrForm>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="item['completed']" name="completed" />
          <label>Completed</label>
        </clr-checkbox-wrapper>

        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="item['inprogress']" name="inprogress" />
          <label>In Progress</label>
        </clr-checkbox-wrapper>

        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox [(ngModel)]="item['project']" name="project" />
          <label>Project</label>
        </clr-checkbox-wrapper>

        <clr-date-container>
          <label>Date</label>
          <input type="date" clrDate name="date" [(ngModel)]="item['date']">
        </clr-date-container>

        <clr-select-container>
          <label>Truck/Trailer</label>
          <select clrSelect name="trucks" [(ngModel)]="item['truck']">
            <option selected disabled hidden></option>
            <option *ngFor="let truck of (trucks$ | async)" value="{{truck['_id']}}">{{truck['name']}}</option>
          </select>
        </clr-select-container>

        <!-- <clr-select-container>
          <label>Technician</label>
          <select clrSelect name="technicians" [(ngModel)]="item['technician']">
            <option selected disabled hidden></option>
            <option *ngFor="let technician of (technicians$ | async)" value="{{technician['_id']}}">
              {{technician['name']}}</option>
          </select>
        </clr-select-container> -->

        <clr-input-container>
          <label>Title</label>
          <input clrInput type="text" [(ngModel)]="item['name']" name="Order Title" />
        </clr-input-container>
        <clr-input-container>
          <label>Description</label>
          <input clrInput type="text" [(ngModel)]="item['description']" name="Description" />
        </clr-input-container>
        <clr-input-container>
          <label>Notes</label>
          <input clrInput type="text" [(ngModel)]="item['notes']" name="Notes" />
        </clr-input-container>
        <button class="btn btn-primary" type="submit" [disabled]="!item['name']" (click)="submit()">
          Submit
        </button>
      </form>


    </div>
  </div>
</clr-main-container>