import { Component, OnInit } from '@angular/core';
import { CommodityService } from 'src/app/core/services/commodity.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newcommodity',
  templateUrl: './newcommodity.component.html',
  styleUrls: ['./newcommodity.component.scss']
})
export class NewcommodityComponent implements OnInit {

  public item: any = {
    "name": ""
  };

  constructor(private commodity: CommodityService, private router: Router) { }

  ngOnInit() {
  }


  submit() {
    this.commodity.createCommodity(this.item).subscribe(
      response => {
        console.log(response);
        this.router.navigate(['/entities']);
      },
      err => console.log(err)
    );
  }

}
